import jquery from 'jquery';

jquery(function() {
  
  /** 
   * Cast pre zobrazenie skrytej časti článku cez značku {end} */
	var cely = jquery('.cely_clanok'); //Odkaz s class=cely_clanok
	var ostatok = jquery('.ostatok');  //Span s class=ostatok do ktoreho je obalena zvysna cast clanku
	ostatok.hide();               //Skryjem "ostatok"
	cely.on("click", function() {       //Pri kliku na odkaz cely
		jquery(this).fadeOut(200, function() {
			jquery(this).remove();         //Odstránim odkaz
			ostatok.slideDown('slow');//Ukazem ostatok
		});
		return false; 					  //Zakážem odkaz
	});
  

	//Pre zobrazenie celého oznamu
	var cely = jquery('.cely_oznam'); //Nájdem doplnok textu
	var textC = cely.next().html();		//Najdem cely text
	var textU = cely.prev();		//Najdem upraveny text
	cely.next().hide().remove();              //Skryjem ho
	cely.on("click",function() { //Pri kliku na článok
		textU.append('<span class="ost">' + textC + '</span>');
		var ost = jquery('.ost');
		ost.hide();
		jquery(this).fadeOut(200, function() {
			jquery(this).remove();	//Odstránim odkaz
			ost.slideDown('slow');
		});//.next().slideDown('slow');//fadeIn('slow');			  //Skryjem samotný odkaz
		return false; 					  //Zakážem odkaz
	});

	// Pre zobrazenie tagu, ktorý je pred položkou s id=nova_polozka na ktorú sa kliklo
//	jquery('#nova_polozka').on("click",function() {
//		jquery(this).prev().slideDown(1000);
//		jquery(this).delay(1000).fadeTo(500,.01).slideUp(500, function() {
//			jquery(this).remove();	//Odstránim odkaz
//		});
//		return false;
//	});

	// Pre zobrazenie tagu, ktorý je pred položkou s id=nova_upload, na ktorú sa kliklo
	// a pre ukrytie nasledujúceho
//	jquery('#nova_upload').on("click",function()	{
//		jquery(this).prev().slideDown(1000);
//		jquery(this).next().slideUp(1000);
//		jquery(this).fadeTo(500,.01).slideUp(500, function() {
//			jquery(this).remove();	//Odstránim odkaz
//		});
//		return false;
//	});

	// Pre zmiznutie hlásenia cez funkciu stav_dobre

//	jquery("#cela_nav").css({'display': 'none'});
//	jquery(".menu_ukaz").on("click",function() {
//		jquery(".menu_ukaz").fadeOut("fast", function(){
//			jquery("#cela_nav").slideDown(1000);
//		});
//		return false;
//	});

//  var login_form = jquery("#my-sign-in-form");
//	jquery(".log-in").on("click",function() {
//		login_form.slideDown(500, function() {
//      jquery(".prihlasujem").css("opacity", 1);
//    });
//		return false;
//	});
//
//	jquery("#frmsignInForm-login").on("click",function() {
//    login_form.after('<div class="prihlasujem">Prihlasujem...</div>');
//    login_form.fadeTo(250, .3);
//	});

var card_view_link = document.getElementById("card_view_link");
var table_view_link = document.getElementById("table_view_link");
var card_view = document.getElementById("card_view");
var table_view = document.getElementById("table_view");
if (table_view_link != null) { table_view_link.disabled = true; }


jquery("#card_view_link").on("click",function() {
  card_view.classList.remove("d-none");
  table_view.classList.add("d-none");
  table_view_link.disabled = false;
  card_view_link.disabled = true;
  table_view_link.classList.remove("btn-outline-secondary");
  card_view_link.classList.remove("btn-outline-success");
  table_view_link.classList.add("btn-outline-success");
  card_view_link.classList.add("btn-outline-secondary");
});

jquery("#table_view_link").on("click",function() {
  table_view.classList.remove("d-none");
  card_view.classList.add("d-none");
  table_view_link.disabled = true;
  card_view_link.disabled = false;
  table_view_link.classList.remove("btn-outline-success");
  card_view_link.classList.remove("btn-outline-secondary");
  table_view_link.classList.add("btn-outline-secondary");
  card_view_link.classList.add("btn-outline-success");
});
	
});

/*
import 'jsonlylightbox/js/lightbox.js';
var lightbox = Lightbox();

var lightBoxOptions = {
  prevImg: true,
  nextImg: true,
  hideCloseBtn: false,
  carousel: true,
  animation: 400,
  nextOnClick: true,
  responsive: true,
  maxImgSize: 0.8,
  keyControl: true
};

lightbox.load(lightBoxOptions);
*/